import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { get, has } from "lodash";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { commonHooks } from "../common";
import { RowExpansionToggleCell } from "./RowExpansionToggleCell";
import { RowSelectorCell } from "./RowSelectorCell";
import { SortDirections } from "./SortDirections";
import { TableCellAlignments } from "./TableCellAlignments";
import { Td } from "./Td";
import { IThProps, Th } from "./Th";
import { Tr } from "./Tr";

// Redecalare forwardRef
// this is to be able to use React.forwardRef to wrap Table component but to pass TRowData generic...
// more https://fettblog.eu/typescript-react-generic-forward-refs/
declare module "react" {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export type TTableColumnFormat<TRowData> = "date" | "money" | ((rowData: TRowData, column: ITableColumnProps<TRowData>) => any);

export interface ITableApi {
    getSelectedRows: () => TRowDataId[];
}

export interface IGenericRendererProps {
    row?: IRowStateItem;
}

export interface ITableColumnProps<TRowData extends {}> {
    key: string; // unique identifier for column (used to store/restore sort and filter state)
    header: string | React.ReactNode | ((column: ITableColumnProps<TRowData>) => string | React.ReactNode);
    accessor?: string;
    isSortable?: boolean;
    compareFn?: (left: any, right: any) => 1 | 0 | -1;
    alignment?: TableCellAlignments;
    cell?: (data: TRowData, rowIndex: number, cellIndex: number, props: IGenericRendererProps) => string | React.ReactNode;
    cellClassName?: string;
    cellOddClassName? : string;
    cellEvenClassName? : string;
    format?: TTableColumnFormat<TRowData>;
    className?: string;
    headerClassName? :string;
    isVisible?: () => boolean;
    colSpan?: (data: TRowData, rowIndex: number, cellIndex: number, props: IGenericRendererProps) => number;
}
export interface ITableProps<TRowData> {
    columns: Array<ITableColumnProps<TRowData>>;
    data: TRowData[];
    onRowClick?: (data: TRowData) => void;
    onRowDoubleClick?: (data: TRowData) => void;
    onRowSelectionAllChange?: () => void;
    onRowSelectionChange?: (data: TRowData, isSelected: boolean) => void;
    onRowHover?: (data: TRowData, isHovered: boolean) => void;
    idField?: keyof TRowData;
    enableRowSelection?: boolean;
    enableRowExpansion?: boolean;
    enableRowExpansionOnRowClick?: boolean;
    expandedRow?: (data: TRowData) => string | React.ReactNode;
    noRowDataMessage?: string | React.ReactNode | React.ReactNode[] | (() => React.ReactNode | React.ReactNode[]);
    isRowSelectableResolver?: (rowData: TRowData) => boolean;
    headerClassName?: string;
    extraHeaders?: string | React.ReactNode | (() => React.ReactNode);
    rowClassName?: string | ((rowData: TRowData, rowIndex: number) => string);
    sortColumn?: string;
    sortDirection?: SortDirections;
}

interface IColumnMap<TRowData extends {}> {
    [key: string]: ITableColumnProps<TRowData>;
}

type TSortState = Array<{
    key: string;
    direction: SortDirections;
}>;

interface IRowStateItem {
    isExpanded: boolean;
    isSelected: boolean;
    isHovered: boolean;
}

type TRowState = Map<string | number, IRowStateItem>;

type TRowDataId = string | number;

export const Table = React.forwardRef(
    <TRowData extends {}>(
        {
            columns,
            data,
            onRowClick,
            onRowDoubleClick,
            onRowSelectionChange,
            onRowSelectionAllChange,
            onRowHover,
            idField,
            expandedRow,
            enableRowSelection = false,
            enableRowExpansion = false,
            enableRowExpansionOnRowClick = false,
            isRowSelectableResolver,
            noRowDataMessage,
            headerClassName,
            extraHeaders,
            rowClassName,
            sortColumn,
            sortDirection,
        }: ITableProps<TRowData>,
        ref: React.Ref<ITableApi>
    ) => {
        const createColumnMap = (columnsArray: Array<ITableColumnProps<TRowData>>) =>
            columnsArray.reduce((map, column) => ({ ...map, [column.key]: column }), {} as IColumnMap<TRowData>);
        const [processedData, setProcessedData] = useState<TRowData[]>([]);
        const [processedColumns, setProcessedColumns] = useState<Array<ITableColumnProps<TRowData>>>([]);
        const [columnMap, setColumnMap] = useState<IColumnMap<TRowData>>({});
        const [sortState, setSortState] = useState<TSortState>([]);
        const [rowState, setRowState] = useState<TRowState>(new Map());
        const [rowSelectionCache, setRowSelectionCache] = useState<TRowDataId[]>([]);
        const [maxSelectableRowCount, setMaxSelectableRowCount] = useState<number>();
        const maxSelectableRowCountRef = useRef<number>();
        const processedDataRef = useRef<TRowData[] | []>([]);
        const rowStateRef = useRef<TRowState>();
        const rowSelectionCacheRef = useRef<TRowDataId[]>([]);
        const safeRef = commonHooks.useForwardedRef(ref);

        useEffect(() => {
            const filteredColumns = columns.filter((colum) => (colum.isVisible ? colum.isVisible() : true));
            const newProcessedColumns = [...(filteredColumns || [])];

            if (enableRowSelection) {
                newProcessedColumns.unshift({
                    key: "row_select_unique_key",
                    header: headerRowSelectorCellRenderer,
                    cell: rowSelectorCellRenderer,
                    alignment: TableCellAlignments.Center,
                    isSortable: false,
                });
            }

            if (enableRowExpansion) {
                newProcessedColumns.push({
                    key: "row_expanded_unique_key",
                    header: "",
                    cell: rowExpanderCellRenderer,
                    alignment: TableCellAlignments.Center,
                    isSortable: false,
                });
            }

            setProcessedColumns(newProcessedColumns);

            setColumnMap(createColumnMap(newProcessedColumns));
        }, [columns, enableRowSelection, enableRowSelection]);

        /**
         * This effect is responsible to sort and process data when data or table state changes.
         * The processed data will be stored in state to prevent reprocessing on every render.
         */
        useEffect(() => {
            const newProcessedData = [...(data || [])];

            newProcessedData.sort((left, right) => {
                for (let i = 0; i < sortState.length; i++) {
                    const columnSortState = sortState[i];
                    const columnConfig = columnMap[columnSortState.key];
                    const { compareFn = defaultCompareFn } = columnConfig;
                    const rawLeftValue = resolveRawValue(columnConfig, left);
                    const rawRightValue = resolveRawValue(columnConfig, right);
                    const compareResult = compareFn(rawLeftValue, rawRightValue);

                    if (compareResult !== 0) {
                        if (columnSortState.direction === SortDirections.Desc) {
                            return compareResult === 1 ? -1 : compareResult === -1 ? 1 : -1;
                        }

                        return compareResult;
                    }
                }

                return 0;
            });

            setProcessedData(newProcessedData);

            const newMaxSelectableRowCount = newProcessedData.reduce(
                (aggr, rowData, rowIndex) => (isRowSelectable(rowIndex) ? aggr + 1 : aggr),
                0
            );

            setMaxSelectableRowCount(newMaxSelectableRowCount);

            // processedDataRef.current = newProcessedData;
        }, [data, sortState, columnMap, columnMap, maxSelectableRowCount]);

        useEffect(() => {
            if (! sortState.length && sortColumn) {
                setSortState([{
                    key: sortColumn,
                    direction: sortDirection ? sortDirection : SortDirections.Desc,
                }]);
            }
        }, []);

        useEffect(() => {
            clearRemovedExpandedRows();
        }, [data]);

        /**
         * Expose api to consumer component by making use of ref
         */
        useImperativeHandle(safeRef, () => ({
            getSelectedRows,
        }));

        /**
         * Responsible to create cache with selected row data id's
         */
        useEffect(() => {
            const newRowSelectionCache: Array<string | number> = [];

            rowState.forEach((rowStateItem, rowDataId) => {
                if (rowStateItem.isSelected === true) {
                    newRowSelectionCache.push(rowDataId);
                }
            });

            setRowSelectionCache(newRowSelectionCache);
        }, [rowState]);

        rowStateRef.current = rowState;
        processedDataRef.current = processedData;
        rowSelectionCacheRef.current = rowSelectionCache;
        maxSelectableRowCountRef.current = maxSelectableRowCount;

        const rowExpanderCellRenderer = (rowData: TRowData, rowIndex: number) => (
            <RowExpansionToggleCell onClick={toggleRowExpansion} isExpanded={isRowExpanded(rowIndex)} rowIndex={rowIndex} />
        );

        const rowSelectorCellRenderer = (rowData: TRowData, rowIndex: number) =>
            isRowSelectable(rowIndex) ? (
                <RowSelectorCell onChange={toggleRowSelection} isSelected={isRowSelected(rowIndex)} indexType="row" index={rowIndex} />
            ) : null;

        const headerRowSelectorCellRenderer = (column: ITableColumnProps<TRowData>, columnIndex: number) => (
            <RowSelectorCell
                onChange={toggleAllRowSelection}
                isSelected={areAllRowsSelected()}
                indexType="row"
                index={columnIndex}
                disabled={!maxSelectableRowCountRef.current}
            />
        );

        const renderNoRowDataMessage = () => {
            const rowSpan = processedColumns.length;
            let renderedMessage: string | React.ReactNode | React.ReactNode[] = "";

            if (typeof noRowDataMessage === "string") {
                renderedMessage = <div className="text-center text-sm py-6">{noRowDataMessage}</div>;
            } else if (typeof noRowDataMessage === "function") {
                renderedMessage = noRowDataMessage();
            } else {
                renderedMessage = noRowDataMessage;
            }

            return (
                <Tr index={0} key={`${0}-${0}`}>
                    <Td colSpan={rowSpan}>
                        <React.Fragment>{renderedMessage}</React.Fragment>
                    </Td>
                </Tr>
            );
        };

        /**
         * getRowDataIdField
         * Helper to get the identifier field for row data
         */
        const getRowDataIdField = () => (idField || "id") as keyof TRowData;

        /**
         * resolveRawValue
         * Helper to resolve to unformatted and unrendered value which can be resolved or not.
         * If not value is configured for accessorer, the key will be used as accessor.
         * If nothing could be resolved, an empty string will be returned.
         */
        const resolveRawValue = (column: ITableColumnProps<TRowData>, rowData: TRowData) => {
            const accessor = column.accessor || (column.key as keyof TRowData);

            return accessor && has(rowData, accessor) ? get(rowData, accessor) : "";
        };

        /**
         * resolveCellValue
         * Helper to resolve the rendered or formatted or raw cell value which will be rendered on the screen
         */
        const resolveCellValue = (column: ITableColumnProps<TRowData>, rowData: TRowData, rowIndex: number, cellIndex: number): any => {
            const { format, cell } = column;

            if (cell) {
                const rowStateItem = rowStateRef.current?.get(getRowDataIdByRowIndex(rowIndex));

                return cell(rowData, rowIndex, cellIndex, { row: rowStateItem! });
            }

            const rawValue = resolveRawValue(column, rowData);

            if (format) {
                if (typeof format === "function") {
                    return format(rowData, column);
                } else {
                    switch (format) {
                        case "date":
                            return new Date(rawValue as any).toLocaleString("en-GB");
                    }
                }
            }

            return rawValue;
        };

        /**
         * resolveExpandedRowValue
         */
        const resolveExpandedRowValue = (rowData: TRowData, rowIndex: number): any => expandedRow && expandedRow(rowData);

        /**
         * resolveCellColSpan
         */
        const resolveCellColSpan = (column: ITableColumnProps<TRowData>, rowData: TRowData, rowIndex: number, cellIndex: number): number => {
            const { colSpan } = column;

            if (colSpan) {
                const rowStateItem = rowStateRef.current?.get(getRowDataIdByRowIndex(rowIndex));

                return colSpan(rowData, rowIndex, cellIndex, { row: rowStateItem! });
            }

            return 1;
        };

        /**
         * getRowDataIdByRowIndex
         */
        const getRowDataIdByRowIndex = (rowIndex: number) => {
            const rowData = processedDataRef.current[rowIndex];
            const idFieldName = getRowDataIdField();

            return rowData[idFieldName] as unknown as string;
        };

        const getRowIndexByRowDataId = (rowDataId: TRowDataId) =>
            processedDataRef.current.findIndex((rowData) => (rowData[getRowDataIdField()] as unknown as TRowDataId) === rowDataId);

        const createRowStateItem = () => ({ isExpanded: false, isSelected: false, isHovered: false });

        const toggleRowSelection = (rowIndex: number) => {
            const rowStateItem = rowStateRef.current?.get(getRowDataIdByRowIndex(rowIndex));

            toggleOrSetRowStateItemBoolProperty("isSelected", rowIndex);

            if (onRowSelectionChange) {
                setTimeout(() => onRowSelectionChange(processedData[rowIndex], !rowStateItem?.isSelected));
            }
        };

        const areAllRowsSelected = () => {
            const currentRowSelectionCache = rowSelectionCacheRef.current;
            const currentMaxSelectableRowCount = maxSelectableRowCountRef.current;

            return currentRowSelectionCache.length === currentMaxSelectableRowCount && currentMaxSelectableRowCount > 0;
        };

        const toggleAllRowSelection = () => {
            const currentProcessedData = processedDataRef.current;
            const currentRowState = rowStateRef.current;
            const newRowState = new Map(currentRowState || new Map());
            let areAllSelected = true;
            let areSomeSelected = false;
            let areNoneSelected = true;

            if (currentRowState) {
                for (let i = 0; i < currentProcessedData.length; i++) {
                    const rowIndex = i;
                    const rowDataId = currentProcessedData[rowIndex][getRowDataIdField()];
                    const isSelectable = isRowSelectable(rowIndex);

                    if (isSelectable) {
                        const isSelected = newRowState.get(rowDataId)?.isSelected === true;

                        if (areAllSelected && !isSelected) {
                            areAllSelected = false;
                        }

                        if (!areSomeSelected && isSelected) {
                            areSomeSelected = true;
                        }

                        if (areNoneSelected && isSelected) {
                            areNoneSelected = false;
                        }

                        if (!areAllSelected && areSomeSelected && !areNoneSelected) {
                            break;
                        }
                    }
                }
            }

            const doSelectAll = !areAllSelected && (areSomeSelected || areNoneSelected);

            currentProcessedData.forEach((rowData) => {
                const rowDataId = rowData[getRowDataIdField()] as unknown as TRowDataId;
                const isSelectable = isRowSelectable(getRowIndexByRowDataId(rowDataId));

                newRowState.set(rowDataId, {
                    ...createRowStateItem(),
                    ...(newRowState.get(rowDataId) || {}),
                    isSelected: doSelectAll && isSelectable,
                });
            });

            setRowState(newRowState);

            if (onRowSelectionAllChange) {
                setTimeout(onRowSelectionAllChange);
            }

            // rowStateRef.current?.keys().filter((rowDataId as ) => currentRowState?.get(rowDataId))
            // processedColumns.length
        };

        const toggleRowExpansion = (rowIndex: number) => {
            toggleOrSetRowStateItemBoolProperty("isExpanded", rowIndex);
        };

        const setRowExpansion = (rowIndex: number, isExpanded: boolean) => {
            toggleOrSetRowStateItemBoolProperty("isExpanded", rowIndex, isExpanded);
        };

        const toggleOrSetRowStateItemBoolProperty = (
            rowStateItemProperty: keyof IRowStateItem,
            rowIndex: number,
            forcedValue?: boolean
        ) => {
            const rowDataId = getRowDataIdByRowIndex(rowIndex);
            const rowStateItem = rowStateRef.current?.get(rowDataId) || createRowStateItem();
            const newRowStateItem = {
                ...rowStateItem,
                [rowStateItemProperty]: forcedValue !== undefined ? forcedValue : !rowStateItem[rowStateItemProperty],
            };

            setRowState((currentRowState) => {
                const newRowState = new Map(currentRowState);

                newRowState.set(rowDataId, newRowStateItem);

                return newRowState;
            });
        };

        const isRowExpanded = (rowIndex: number) => {
            const rowDataId = getRowDataIdByRowIndex(rowIndex);

            return rowStateRef.current?.get(rowDataId)?.isExpanded === true;
        };

        const hasExpandedRows = () => {
            if (rowStateRef.current) {
                for (const rowData of rowStateRef.current) {
                    if (rowData[1].isExpanded) {
                        return true;
                    }
                }
            }

            return false;
        };

        const clearRemovedExpandedRows = () => {
            if (rowStateRef.current) {
                for (const rowStateItem of rowStateRef.current) {
                    const rowDataItemId = rowStateItem[0] as number;
                    const isExpanded = !!rowStateItem[1].isExpanded;

                    const hasDataForCurrentRowState =
                        data.find((rowDataItem) => rowDataItem[getRowDataIdField()] === (rowDataItemId as any)) !== undefined;
                    if (!hasDataForCurrentRowState && isExpanded) {
                        const rowIndex = getRowIndexByRowDataId(rowDataItemId);

                        setRowExpansion(rowIndex, false);
                    }
                }
            }

            return false;
        };

        const isRowSelected = (rowIndex: number) => {
            const rowDataId = getRowDataIdByRowIndex(rowIndex);

            return rowStateRef.current?.get(rowDataId)?.isSelected === true;
        };

        const isRowSelectable = (rowIndex: number) => {
            const rowData = processedDataRef?.current[rowIndex];

            return isRowSelectableResolver && rowData ? isRowSelectableResolver(rowData) : true;
        };

        const getSelectedRows = () => rowSelectionCacheRef.current;

        /**
         * Event handlers
         */

        const handleSortClick: IThProps["onSortClick"] = (key, direction) => {
            const foundColumnStateIndex = sortState.findIndex((columnSortState) => columnSortState.key === key);
            const newSortState = [...sortState];

            if (foundColumnStateIndex !== -1) {
                newSortState.splice(foundColumnStateIndex, 1);
            }

            newSortState.unshift({
                key,
                direction,
            });

            setSortState(newSortState);
        };

        const handleRowClick = (rowIndex: number) => {
            if (enableRowExpansionOnRowClick) {
                toggleRowExpansion(rowIndex);
            }

            if (onRowClick) {
                onRowClick(processedData[rowIndex]);
            }
        };

        const handleRowDoubleClick = (rowIndex: number) => {
            // toggleRowSelection(rowIndex);

            if (onRowDoubleClick) {
                onRowDoubleClick(processedData[rowIndex]);
            }
        };

        const handleRowHover = (rowIndex: number, isHovered: boolean) => {
            toggleOrSetRowStateItemBoolProperty("isHovered", rowIndex, isHovered);

            if (onRowHover) {
                onRowHover(processedData[rowIndex], isHovered);
            }
        };

        const calculatedHasEpandedRows = hasExpandedRows();

        let columnHasColSpanShift = 0;

        return (
            <table className="min-w-full divide-y divide-gray-200">
                <thead className={headerClassName}>
                    {extraHeaders}
                    <tr>
                        {processedColumns?.map((column) => (
                            <Th
                                isSortable={column.isSortable}
                                alignment={column.alignment}
                                columnKey={column.key}
                                key={column.key}
                                onSortClick={column.isSortable ? handleSortClick : undefined}
                                className={classNames(column.className, column.headerClassName)}>
                                {typeof column.header === "function" ? column.header(column) : column.header}
                            </Th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {!processedData?.length
                        ? renderNoRowDataMessage()
                        : processedData?.map((rowData, rowIndex: number) => {
                              const rowDataId = getRowDataIdByRowIndex(rowIndex);
                              const isExpanded = isRowExpanded(rowIndex);
                              const currentRowClassName =
                                  typeof rowClassName === "string" ? rowClassName : !!rowClassName ? rowClassName(rowData, rowIndex) : "";
                              const expandedRowCmp: React.ReactNode = !isExpanded ? null : (
                                  <Tr
                                      index={rowIndex}
                                      key={`${rowDataId}-${rowIndex}-${isExpanded ? 'expanded' : ''}`}
                                      className={classNames("bg-white shadow-md", {
                                          // "blur-sm": calculatedHasEpandedRows && !isExpanded,
                                      })}>
                                      <Td colSpan={processedColumns.length} className=" " key={`${rowIndex}-tr`}>
                                          <Transition
                                              show={isExpanded}
                                              appear={true}
                                              enter="transition ease-in-out duration-0 transform"
                                              enterFrom="translate-y-0"
                                              enterTo="translate-y"
                                              leave="transition ease-in-out duration-0 transform"
                                              leaveFrom="translate-y-full"
                                              leaveTo="translate-y-0">
                                              {isExpanded && resolveExpandedRowValue(rowData, rowIndex)}
                                          </Transition>
                                      </Td>
                                  </Tr>
                              );

                              return (
                                  <React.Fragment key={rowDataId}>
                                      <Tr
                                          index={rowIndex}
                                          key={`${rowDataId}-tr`}
                                          onClick={handleRowClick}
                                          onDoubleClick={handleRowDoubleClick}
                                          isSelected={isRowSelected(rowIndex)}
                                          className={classNames(currentRowClassName, {
                                              "opacity-25 bg-gray-50": calculatedHasEpandedRows && !isExpanded,
                                              "bh-white shadow-inner": isExpanded,
                                          })}
                                          onHover={handleRowHover}>
                                          {processedColumns.map((column , cellIndex) => {
                                              const cellColSpan = resolveCellColSpan(column, rowData, rowIndex, cellIndex);

                                              if (columnHasColSpanShift > 0) {
                                                  columnHasColSpanShift--;
                                                  return null;
                                              }

                                              if (cellColSpan > 1) {
                                                  columnHasColSpanShift = cellColSpan - 1;
                                              }

                                              return <Td
                                                      alignment={column.alignment}
                                                      key={`${cellIndex}-${rowDataId}`}
                                                      rowIndex={rowIndex}
                                                      colSpan={cellColSpan}
                                                      className={column.cellClassName}
                                                      oddClassName={column.cellOddClassName}
                                                      evenClassName={column.cellEvenClassName}>
                                                      <React.Fragment>{resolveCellValue(column, rowData, rowIndex, cellIndex)}</React.Fragment>
                                                  </Td>;
                                          })}
                                      </Tr>
                                      {expandedRowCmp}
                                  </React.Fragment>
                              );
                          })}
                </tbody>
            </table>
        );
    }
);

/**
 * defaultCompareFn
 * The default column compare function when isSortable is true and no sorter is provided.
 */
const defaultCompareFn = (left: any, right: any): 1 | 0 | -1 => {
    if (left > right) {
        return 1;
    }

    if (left < right) {
        return -1;
    }

    return 0;
};
