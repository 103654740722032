import React, { HTMLAttributes } from "react";
import { commonTypes, twMerge } from "../common";
import { Text } from "./Text";
import { Title } from "./Title";
import { TFontSize } from "./types";

interface SubHeaderTitleProps extends HTMLAttributes<HTMLDivElement> {
    title: string;
    description?: string | React.ReactNode;
    textSize?: TFontSize;
    color?: commonTypes.TColor;
}

export function SubHeaderTitle({ title, description, textSize = "base", className, ...restProps }: SubHeaderTitleProps) {
    return (
        <div className={twMerge("my-6", className)} {...restProps}>
            <Title size={textSize}>{title}</Title>
            {description && <Text>{description}</Text>}
        </div>
    );
}
